var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("控制中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("入點比例管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold"},[_vm._v(" 入點比例管理 ")]),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          flex-xl-row\n          align-items-start align-items-xl-center\n        "},[(_vm.checkPermission([_vm.consts.POINT_RULE_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3",attrs:{"variant":"primary","to":{ name: 'PointRuleCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增入點比例 ")]):_vm._e(),_c('b-form-select',{attrs:{"options":_vm.merchants,"disabled":_vm.merchants.length == 2},on:{"change":_vm.fetchPointRules},model:{value:(_vm.merchantId),callback:function ($$v) {_vm.merchantId=$$v},expression:"merchantId"}})],1)]),_c('div',{staticClass:"d-flex mb-4 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "})]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.pointRules,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
            name: 'PointRuleDetail',
            params: {
              pointRuleId: data.item.id,
            },
          }}},[_vm._v("查看 ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: 'PointRuleEdit',
            params: {
              pointRuleId: data.item.id,
            },
          }}},[_vm._v("編輯 ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deletePointRule(data.item)}}},[_vm._v(" 刪除 ")])]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{staticClass:"separated",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage},on:{"change":function (page) { return _vm.fetchPointRules(page); }},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }