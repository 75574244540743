<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>控制中心</b-breadcrumb-item>
            <b-breadcrumb-item active>入點比例管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
          入點比例管理
        </h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.POINT_RULE_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'PointRuleCreate' }"
            ><i class="fa fa-plus"></i>新增入點比例
          </b-button>
          <b-form-select
            v-model="merchantId"
            :options="merchants"
            :disabled="merchants.length == 2"
            @change="fetchPointRules"
          ></b-form-select>
        </div>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        ></div>
      </div>
      <b-table
        striped
        hover
        responsive
        :items="pointRules"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="ml-2"
            variant="inverse-primary"
            :to="{
              name: 'PointRuleDetail',
              params: {
                pointRuleId: data.item.id,
              },
            }"
            >查看
          </b-button>
          <b-button
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'PointRuleEdit',
              params: {
                pointRuleId: data.item.id,
              },
            }"
            >編輯
          </b-button>
          <b-button
            class="ml-2"
            variant="inverse-danger"
            @click="deletePointRule(data.item)"
          >
            刪除
          </b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          @change="(page) => fetchPointRules(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointRuleApi from "../../../apis/point-rule";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts: consts,
      isLoading: false,
      pointRules: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      fields: [
        {
          key: "key",
          label: "比對值",
        },
        {
          key: "value",
          label: "點數/倍數",
        },
        {
          key: "type",
          label: "比對值類型",
        },
        {
          key: "event_id",
          label: "活動代碼",
        },
        {
          key: "order",
          label: "順序",
        },
        {
          key: "cate",
          label: "分類",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 20,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchMerchants();
  },
  methods: {
    async fetchPointRules(page) {
      if (!this.merchantId) {
        this.total = 0;
        this.currentPage = 1;
        this.pointRules = [];
        return;
      }
      try {
        this.isLoading = true;
        const { data } = await pointRuleApi.getPointRules(this.merchantId, {
          page: page || this.currentPage,
          per_page: this.perPage || 20,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.currentPage = data.meta.current_page;
        this.pointRules = data.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant;
      if (data.data.length == 1) {
        defaultMerchant = data.data[0];
      } else {
        defaultMerchant = data.data
          .filter((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE)
          .shift();
      }

      if (defaultMerchant) {
        this.merchantId = defaultMerchant.id;
        this.fetchPointRules();
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async deletePointRule(pointRule) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
              <div class="d-block">
                <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
              </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await pointRuleApi.removePointRule(
              pointRule.merchant_id,
              pointRule.id,
              pointRule
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.fetchPointRules()();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
  },
};
</script>
